import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5dd8d4dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "admin-home" }
const _hoisted_2 = { class: "welcome" }
const _hoisted_3 = { style: {"color":"#5c5c5c"} }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.getTranslation('core.common.welcomeTo')) + " " + _toDisplayString(_ctx.title), 1),
      (_ctx.showSideBar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.homeToGetStarted')) + ".", 1),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, "- " + _toDisplayString(_ctx.getTranslation('core.common.or')) + " - ", 1)
            ]),
            _createVNode(_component_b_button, {
              style: {"margin-top":"20px"},
              onClick: _ctx.toggleNav
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.clickHere')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}