

import { defineComponent } from 'vue';
import sidebarStore from '@/components/sidebar/store/SidebarStore';
import { APPLICATION_DISPLAY_NAME } from '@/constants/env';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'home-screen',
    props: {
        showSideBar: { type: Boolean, default: true },
        title: { type: String, default: APPLICATION_DISPLAY_NAME },
    },
    setup() {
        function toggleNav() {
            sidebarStore.getInstance().toggleSidebar();
        }

        return {
            getTranslation,
            getTitleCaseTranslation,
            toggleNav,
        };
    },
});

